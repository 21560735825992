import React, { useEffect } from "react";
import { useMeetingManager, useNotificationDispatch, Severity, ActionType, } from "amazon-chime-sdk-component-library-react";
import routes from "../contants/route";
import { useLocation } from "react-router-dom";
import { DeviceUUID } from "device-uuid";
import { LOADED } from "../variables/constants";
var NoMeetingRedirect = function (_a) {
    var children = _a.children;
    var dispatch = useNotificationDispatch();
    var meetingManager = useMeetingManager();
    var search = useLocation().search;
    var params = new URLSearchParams(search);
    var token = params.get("token");
    var payload = {
        severity: Severity.INFO,
        message: "Could not find requsted meeting.",
        autoClose: true
    };
    useEffect(function () {
        if (!meetingManager.meetingSession) {
            if (token) {
                var uuid = new DeviceUUID().get();
                window.location.href = routes.HOME + "?token=" + token + "&" + LOADED + "=" + uuid;
            }
            else {
                dispatch({
                    type: ActionType.ADD,
                    payload: payload,
                });
                window.location.href = routes.HOME;
            }
        }
    }, []);
    return React.createElement(React.Fragment, null, children);
};
export default NoMeetingRedirect;
